import { getCompliancePartnerID } from './localStorageHandler/userProfile';

// This component is to send an email from the user side without depending on customer.io
export const sendEmail = (
  recipient: string,
  subject: string,
  body: string,
  fromEmail?: string
) => {
  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);

  // Construct the base mailto URL
  let mailtoUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${recipient}&su=${encodedSubject}&body=${encodedBody}`;

  // If fromEmail exists, add it to the URL
  if (fromEmail && fromEmail.trim() !== '') {
    mailtoUrl = `https://mail.google.com/mail/u/${encodeURIComponent(fromEmail)}/?view=cm&fs=1&to=${recipient}&su=${encodedSubject}&body=${encodedBody}`;
  }

  // Open the URL in a new tab
  window.open(mailtoUrl, '_blank');
};

/**
 * Validates an array of email addresses and returns only the valid ones.
 * @param emails - Array of email addresses to validate.
 * @returns Array of valid email addresses.
 */
export const filterValidEmails = (emails: string[]): string[] => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emails.filter((email) => emailRegex.test(email));
};

/**
 * Get the email template for the partner.
 * This is a html file that contains the email template uploaded on customer.io
 *
 * @returns The email template for the partner.
 */
export const getTemplateForPartner = () => {
  const partnerId = getCompliancePartnerID();
  switch (partnerId) {
    case '669a55c894721303b632d1d5':
      return '/keyes-email-template.html';
    case '660ff06fcd5f6a47996d8c76':
      return '/bw-email-template.html';
    default:
      return null;
  }
};

/**
 * Get the initial reply to options for the partner.
 *
 * @returns The initial reply to options for the partner.
 */
export const getInitialReplyToOptionsForPartner = () => {
  const partnerId = getCompliancePartnerID();
  if (partnerId === '669a55c894721303b632d1d5') {
    return [
      {
        name: 'Keyes Transaction Team',
        email: 'maxhome@keyes.com',
      },
    ];
  } else if (partnerId === '660ff06fcd5f6a47996d8c76') {
    return [
      {
        name: 'B&W Central Support Services',
        email: 'centralsupportservices@bairdwarner.com',
      },
    ];
  } else {
    return [];
  }
};
