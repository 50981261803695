import { GetDocumentExtractionStatusResponse } from 'app/interfaces/responses/document';
import api from 'app/utils/api';
import apiV2 from 'app/utils/apiV2';

export class DocumentService {
  public async getDocument(documentID: string) {
    return api.get(`/document/${documentID}`);
  }

  public async getDocumentExtractionStatus(
    documentIds: string[]
  ): Promise<GetDocumentExtractionStatusResponse> {
    const documentIdString = documentIds.join(',');
    return apiV2.get(`/documents/status?ids=${documentIdString}`);
  }
}
