export const transactionMilestones: Record<string, string> = {
  PRE_CONTRACT: 'PRE_CONTRACT',
  UNDER_CONTRACT: 'UNDER_CONTRACT',
  CLOSING: 'CLOSING',
  CLOSED: 'CLOSED',
  CLOSED_FILE_COMPLETE: 'CLOSED_FILE_COMPLETE',
  TERMINATED: 'TERMINATED',
  FALL_THROUGH: 'FALL_THROUGH',
  WITHDRAWN: 'WITHDRAWN',
};
