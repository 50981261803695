import React, { useEffect, useState } from 'react';
import { Table, Button, Dropdown, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { MoreOutlined, SendOutlined, StopOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import TaskPreviewModal from './TaskPreviewModal';
import '../styles/TaskTable.scss';
import { getSimpleDateString } from 'app/utils/datetime';
import { TaskStatus } from '../types';
import TaskStatusMap from '../utils/taskUtils';
import { TaskService } from '../services';
import { getCustomIdFromUrl } from 'app/utils/url';

const TaskTable: React.FC = () => {
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [tasks, setTasks] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const transactionID = getCustomIdFromUrl('transactionID');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchTasks = async (page = 1, limit = 10) => {
    setLoading(true);
    try {
      // Use transaction ID if available, otherwise fetch by trigger date
      if (transactionID) {
        const result: any =
          await TaskService.getTasksByTransaction(transactionID);
        setTasks(result?.data?.tasks);
      } else {
        const result: any = await TaskService.getTasksByTriggerDate({
          page,
          limit,
        });
        setTasks(result?.data?.tasks);
        setPagination({
          ...pagination,
          current: result?.data?.page,
          total: result?.data?.total,
        });
      }
    } catch (error) {
      message.error('Failed to fetch tasks');
    } finally {
      setLoading(false);
    }
  };

  const getStatusTag = (status: string) => {
    const taskStatus = status as TaskStatus;
    const statusConfig = TaskStatusMap[taskStatus];
    const cssClassName = (statusConfig?.text || status).replace(/[\s_]/g, '');

    return (
      <span className={`task-status task-status--${cssClassName}`}>
        {statusConfig?.text || status}
      </span>
    );
  };

  const handleMenuClick = async (action: string, task: any) => {
    setLoading(true);
    try {
      switch (action) {
        case 'sendNow':
          await TaskService.sendEmailInstantly(task._id);
          message.success('Email sent successfully');
          await fetchTasks();
          break;
        case 'cancel':
          await TaskService.updateTaskStatus(task._id, 'CANCELLED');
          message.success('Task cancelled successfully');
          await fetchTasks();
          break;
      }
    } catch (error) {
      message.error('Failed to process task action');
      console.error('Task action error:', error);
    } finally {
      setLoading(false);
    }
  };

  const getActionMenu = (task: any): MenuProps['items'] => [
    {
      key: 'sendNow',
      label: 'Send Now',
      icon: <SendOutlined />,
      className: 'task-action-send no-preview',
      onClick: () => handleMenuClick('sendNow', task),
    },
    {
      type: 'divider',
    },
    {
      key: 'cancel',
      label: 'Cancel Email',
      icon: <StopOutlined />,
      className: 'task-action-cancel no-preview',
      danger: true,
      disabled: task.status === 'CANCELLED',
      onClick: () => handleMenuClick('cancel', task),
    },
  ];

  const getScheduledSentDisplay = (record: any) => {
    const date = record?.completedAt
      ? getSimpleDateString(record?.completedAt)
      : !record?.trigger?.date
        ? 'To be decided'
        : getSimpleDateString(record?.trigger?.date);
    const status = record?.status as TaskStatus;
    const statusConfig = TaskStatusMap[status];

    if (!statusConfig) return date;

    const IconComponent = statusConfig.icon;

    return (
      <span>
        <IconComponent
          style={{ color: statusConfig.color, marginRight: '0.5rem' }}
        />
        {date}
      </span>
    );
  };

  const columns: ColumnsType<any> = [
    ...(!transactionID
      ? [
          {
            title: 'Address',
            key: 'propertyAddress',
            width: '30%',
            render: (_: any, record: any) =>
              record?.metaData?.data?.propertyAddress,
          },
        ]
      : []),
    {
      title: 'Task',
      key: 'description',
      width: '30%',
      render: (_, record) => record?.metaData?.description,
    },
    {
      title: 'Scheduled/Sent',
      dataIndex: ['trigger', 'date'],
      key: 'scheduledDate',
      width: '15%',
      render: (_, record) => getScheduledSentDisplay(record),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status) => getStatusTag(status),
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <div className="no-preview">
          <Dropdown
            menu={{ items: getActionMenu(record) }}
            trigger={['click']}
            placement="bottomRight"
          >
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const handleRowClick = (record: any, e: React.MouseEvent) => {
    // Don't open preview of task if clicking on elements with class 'no-preview'
    if (!(e.target as Element).closest('.no-preview')) {
      setSelectedTask(record);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <div className="task-table">
      <Table
        loading={loading}
        columns={columns}
        dataSource={tasks}
        pagination={
          transactionID
            ? false
            : {
                ...pagination,
                onChange: (page, pageSize) => {
                  fetchTasks(page, pageSize);
                },
              }
        }
        rowKey="_id"
        onRow={(record) => ({
          onClick: (e) => handleRowClick(record, e),
        })}
      />

      {selectedTask && (
        <TaskPreviewModal
          task={{
            _id: selectedTask?._id,
            name: selectedTask?.metaData.description,
            status: selectedTask?.status,
            scheduledDate: selectedTask?.trigger.date,
            recipient: selectedTask?.recipient,
            emailSubject: selectedTask?.metaData?.subject,
            htmlContent: selectedTask?.metaData?.body,
            taskErrors: selectedTask?.taskErrors,
            // Add other required fields
          }}
          onClose={() => setSelectedTask(null)}
        />
      )}
    </div>
  );
};

export default TaskTable;
