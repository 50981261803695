import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './DocumentList.scss';
import { ComplianceService } from 'app/services/compliance';
import { getCustomIdFromUrl } from 'app/utils/url';
import { getDateString } from 'app/utils/datetime';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import { message, Modal, Spin } from 'antd';
import {
  DownloadOutlined,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

const DocumentList: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<any>([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [downloadingId, setDownloadingId] = useState<string | null>(null);
  const [viewingId, setViewingId] = useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const fetchTransactionDocument = async (
    docId: string,
    transactionId: string
  ) => {
    try {
      const response = await ComplianceService.getDocumentByID(
        transactionId,
        docId
      );
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      return URL.createObjectURL(pdfBlob);
    } catch {
      return 'No Document found';
    }
  };

  const handleViewPdf = async (doc: any) => {
    try {
      const pdfUrl = await fetchTransactionDocument(
        doc._id,
        getCustomIdFromUrl('transactionID')
      );
      if (pdfUrl) {
        setPdfUrl(pdfUrl);
        setModalVisible(true);
      } else {
        message.error('Failed to load the document');
      }
    } catch (error) {
      message.error('An error occurred while loading the document');
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
      setPdfUrl(null);
    }
  };

  const handleDownloadPdf = async (doc: any) => {
    try {
      const documentUrl = await fetchTransactionDocument(
        doc._id,
        getCustomIdFromUrl('transactionID')
      );
      if (documentUrl) {
        const link = document.createElement('a');
        link.href = documentUrl;
        link.download = `${doc.title || 'document'}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(documentUrl);
      } else {
        message.error('Failed to download the document');
      }
    } catch (error) {
      message.error('An error occurred while downloading the document');
    }
  };

  const handleView = async (doc: any) => {
    if (viewingId) return;
    try {
      setViewingId(doc._id);
      await handleViewPdf(doc);
    } finally {
      setViewingId(null);
    }
  };

  const handleDownload = async (doc: any) => {
    if (downloadingId) return;
    try {
      setDownloadingId(doc._id);
      await handleDownloadPdf(doc);
    } finally {
      setDownloadingId(null);
    }
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const response = (await ComplianceService.getDocumentsByOfficeAdmin(
          getCustomIdFromUrl('transactionID')
        )) as any;
        setDocuments(response.data);
      } catch (error: any) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  const component = (
    <div className="document-list">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="header"
      >
        <Typography variant="h5">Documents</Typography>
      </Box>
      <TableContainer component={Paper} className="document-table">
        <Table>
          <TableHead className="table-head-custom">
            <TableRow>
              <TableCell>Document</TableCell>
              <TableCell>Upload Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body-custom">
            {documents?.length > 0
              ? documents.map((doc: any) => (
                  <TableRow key={doc?._id}>
                    <TableCell data-label="Document">{doc?.title}</TableCell>
                    <TableCell data-label="Upload Date">
                      {getDateString(doc?.createdAt) || '-'}
                    </TableCell>
                    {showSpinner ? (
                      <TableCell data-label="Actions">
                        <Spin />
                      </TableCell>
                    ) : (
                      <TableCell data-label="Actions" className="actions-cell">
                        {downloadingId === doc._id ? (
                          <LoadingOutlined spin />
                        ) : (
                          <DownloadOutlined
                            style={{ fontSize: 'large' }}
                            onClick={() => handleDownload(doc)}
                          />
                        )}
                        {viewingId === doc._id ? (
                          <LoadingOutlined spin />
                        ) : (
                          <EyeOutlined
                            style={{ fontSize: 'large' }}
                            onClick={() => handleView(doc)}
                          />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  return (
    <>
      <WaitAndLoadingWithSpinner loading={loading} component={component} />
      <Modal
        title="View Document"
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width="80%"
        bodyStyle={{ height: '75vh' }}
      >
        {pdfUrl ? (
          <iframe
            src={pdfUrl}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="PDF Viewer"
          />
        ) : (
          'No PDF available'
        )}
      </Modal>
    </>
  );
};

export default DocumentList;
