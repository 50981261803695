import api from 'app/utils/api';

export class TaskService {
  static async getTasksByTransaction(transactionID: string) {
    try {
      const response = await api.get(`/task/${transactionID}`);
      return response;
    } catch (error) {
      return error;
    }
  }

  static async updateTaskMetadata(taskId: string, updates: any) {
    try {
      const response = await api.patch(
        `/task/edit-metadata/${taskId}`,
        updates
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  static async updateTaskRecipients(taskId: string, recipients: string[]) {
    try {
      const response = await api.patch(`/task/edit-recipients/${taskId}`, {
        recipients,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async updateTaskStatus(
    taskId: string,
    status: string,
    errors?: string[]
  ) {
    try {
      const response = await api.patch(`/task/status/${taskId}`, {
        status,
        errors,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async sendEmailInstantly(taskId: string) {
    try {
      const response = await api.post(`/task/send-now/${taskId}`);
      return response;
    } catch (error) {
      return error;
    }
  }

  static async getTasksByTriggerDate(queryParams?: any) {
    try {
      const response = await api.get('/task/by-trigger-date', {
        params: queryParams,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}
