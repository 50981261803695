import api from 'app/utils/api';

interface SignatureRecipient {
  role: string;
  name: string;
  email: string;
}

export class SignatureService {
  public static requestSignatures(
    documentIds: string[],
    roleRecipientMap: SignatureRecipient[]
  ) {
    const payload = {
      documentIds,
      roleRecipientMap,
    };

    return api.post('/signatures/request-signatures', payload);
  }

  public static getSignatories(documentIds: string[]) {
    return api.get('/signatures/get-signatories', {
      params: {
        documentIds: documentIds.join(','),
      },
    });
  }
}
