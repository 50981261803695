export enum DocumentStatusEnum {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
}

interface DocumentExtractionStatus {
  id: string;
  title: string;
  status: DocumentStatusEnum;
  parentDocumentId?: string;
}

export interface GetDocumentExtractionStatusResponse {
  data: DocumentExtractionStatus[];
}
