import './TransactionFilterComponent.scss';
import { DatePicker, Drawer, Select } from 'antd';
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import FilterButton from 'app/components/common/Checks/FilterButton';
import { transactionProcessingStatusMap } from '../colorScheme';

interface SelectOption {
  value: string;
  label: string;
}

const statuses: SelectOption[] = [
  {
    value: 'NOT_STARTED',
    label: transactionProcessingStatusMap.NOT_STARTED.name,
  },
  {
    value: 'PROCESSING',
    label: transactionProcessingStatusMap.PROCESSING.name,
  },
  {
    value: 'MAX_APPROVED',
    label: transactionProcessingStatusMap.MAX_APPROVED.name,
  },
  {
    value: 'USER_APPROVED',
    label: transactionProcessingStatusMap.USER_APPROVED.name,
  },
  {
    value: 'SENT_TO_AGENT_FOR_UPDATES',
    label: transactionProcessingStatusMap.SENT_TO_AGENT_FOR_UPDATES.name,
  },
  {
    value: 'DOCS_MISSING',
    label: transactionProcessingStatusMap.DOCS_MISSING.name,
  },
  {
    value: 'VALIDATION_FAILED',
    label: transactionProcessingStatusMap.VALIDATION_FAILED.name,
  },
];

interface TransactionFilterComponentProps {
  selectedStatuses: string[];
  selectedTransactionTypes: string[];
  createdAtFilter: { gte: string; lte: string };
  closingDateFilter: { gte: string; lte: string };
  updatedAtFilter: { gte: string; lte: string };
  onFilterChange: (
    statuses: string[],
    createdAtFilter: { gte: string; lte: string },
    closingDateFilter: { gte: string; lte: string },
    transactionTypes: string[],
    updatedAtFilter: { gte: string; lte: string }
  ) => void;
  onClearFilters: () => void;
  activeFiltersCount: number;
}

const TransactionFilterComponent: React.FC<TransactionFilterComponentProps> = ({
  selectedStatuses,
  selectedTransactionTypes,
  createdAtFilter,
  closingDateFilter,
  updatedAtFilter,
  onFilterChange,
  onClearFilters,
  activeFiltersCount,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const transactionTypeOptions = [
    { value: 'PURCHASE', label: 'Buy' },
    { value: 'LISTING', label: 'List' },
  ];

  const handleStatusSelect = (value: string[]) => {
    onFilterChange(
      value,
      createdAtFilter,
      closingDateFilter,
      selectedTransactionTypes,
      updatedAtFilter
    );
  };

  const handleTransactionTypeSelect = (value: string[]) => {
    onFilterChange(
      selectedStatuses,
      createdAtFilter,
      closingDateFilter,
      value,
      updatedAtFilter
    );
  };

  const handleCreatedDateChange = (value: any) => {
    const newCreatedAtFilter = {
      gte: value?.[0]?.format('MM-DD-YYYY') || '',
      lte: value?.[1]?.format('MM-DD-YYYY') || '',
    };
    onFilterChange(
      selectedStatuses,
      newCreatedAtFilter,
      closingDateFilter,
      selectedTransactionTypes,
      updatedAtFilter
    );
  };

  const handleClosingDateChange = (value: any) => {
    const newClosingDateFilter = {
      gte: value?.[0]?.format('MM-DD-YYYY') || '',
      lte: value?.[1]?.format('MM-DD-YYYY') || '',
    };
    onFilterChange(
      selectedStatuses,
      createdAtFilter,
      newClosingDateFilter,
      selectedTransactionTypes,
      updatedAtFilter
    );
  };

  const handleUpdatedDateChange = (value: any) => {
    const newUpdatedAtFilter = {
      gte: value?.[0]?.format('MM-DD-YYYY') || '',
      lte: value?.[1]?.format('MM-DD-YYYY') || '',
    };
    onFilterChange(
      selectedStatuses,
      createdAtFilter,
      closingDateFilter,
      selectedTransactionTypes,
      newUpdatedAtFilter
    );
  };

  return (
    <div className="filter-component">
      <FilterButton
        onClick={toggleDropdown}
        clearFilters={onClearFilters}
        selectedCount={activeFiltersCount}
      />

      <Drawer
        title={<Typography fontSize="1.5rem">Filters</Typography>}
        open={showDropdown}
        onClose={toggleDropdown}
        width={350}
      >
        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Status
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Status"
            value={selectedStatuses}
            onChange={handleStatusSelect}
            options={statuses}
            allowClear
          />
        </div>

        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Transaction Type
          </Typography>
          <Select
            mode="multiple"
            className="select-component"
            placeholder="Select Transaction Type"
            value={selectedTransactionTypes}
            onChange={handleTransactionTypeSelect}
            options={transactionTypeOptions}
            allowClear
          />
        </div>

        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Created Date
          </Typography>
          <DatePicker.RangePicker
            disabledDate={(current) => current > dayjs()}
            value={
              createdAtFilter.gte && createdAtFilter.lte
                ? [dayjs(createdAtFilter.gte), dayjs(createdAtFilter.lte)]
                : null
            }
            onChange={handleCreatedDateChange}
          />
        </div>

        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Closing Date
          </Typography>
          <DatePicker.RangePicker
            value={
              closingDateFilter.gte && closingDateFilter.lte
                ? [dayjs(closingDateFilter.gte), dayjs(closingDateFilter.lte)]
                : null
            }
            onChange={handleClosingDateChange}
          />
        </div>

        <div className="filter-section">
          <Typography fontSize="1rem" marginBottom="0.5rem">
            Last Updated Date
          </Typography>
          <DatePicker.RangePicker
            value={
              updatedAtFilter.gte && updatedAtFilter.lte
                ? [dayjs(updatedAtFilter.gte), dayjs(updatedAtFilter.lte)]
                : null
            }
            onChange={handleUpdatedDateChange}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default TransactionFilterComponent;
