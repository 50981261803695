import {
  Modal as AntdModal,
  Empty,
  Input,
  Popconfirm,
  Table,
  Tooltip,
  message,
} from 'antd';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Close, ContentCopy, Delete, Search } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getAdminProfile,
  getCompliancePartnerID,
} from 'app/utils/localStorageHandler/userProfile';

import AddressForm from 'app/components/common/AddressForm/AddressForm';
import { AddressService } from 'app/components/common/AddressForm/AddressService/AddressService';
import { AppURLs } from 'app/utils/appURLs';
import { ComplianceService } from 'app/services/compliance';
import { ComplianceTransaction } from 'app/interfaces/ComplianceTransaction';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import LoadingProgressBar from 'app/components/common/LoadingProgressBar';
import { TRANSACTION_TYPE_OPTIONS } from '../utils/transactionTypeMap';
import { TransactionBuilder } from '../utils/transactionBuilder';
import TransactionFilterComponent from '../../components/TransactionFilterComponent';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import { getMMDDYYYYString } from 'app/utils/datetime';
import { getTransactionFilterHandler } from './filterHandler';
import { transactionProcessingStatusMap } from '../../components/colorScheme';
import { transactionSourceAtom } from 'app/atomManagers/transactionSourceAtom';
import useDebounce from 'app/hooks/UseDebounce';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

interface ComplianceTransactionsPageProps {
  complianceTransactions?: boolean;
}

function formatString(str: string) {
  if (!str) return '';
  str = str.toLowerCase();
  str = str.replace(/[_-]/g, ' ');
  str = str.replace(/\b\w/g, (c) => c.toUpperCase());
  return str;
}

const ComplianceTransactionsPage: React.FC<ComplianceTransactionsPageProps> = ({
  complianceTransactions = false,
}) => {
  const navigate = useNavigate();
  const [filterHandler, setFilterHandler] = useState(() =>
    getTransactionFilterHandler()
  );
  const [storedFilters, setStoredFilters] = useState(
    filterHandler.getStoredFilters()
  );
  const setTransactionSource = useSetRecoilState(transactionSourceAtom);

  // State
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [progressBarLoading, setProgressBarLoading] = useState(false);
  const [transactions, setTransactions] = useState<ComplianceTransaction[]>([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState<string>('-createdAt');
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  // Modal states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [transactionAlreadyExists, setTransactionAlreadyExists] =
    useState(false);
  const [alreadyExisitingTransaction, setAlreadyExisitingTransaction] =
    useState<any>(null);
  const [loadingAgents, setLoadingAgents] = useState(false);
  const [agentOptions, setAgentOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [resetKey, setResetKey] = useState(0);

  // Consolidated filter state
  const [filters, setFilters] = useState({
    selectedStatuses: storedFilters.selectedStatuses,
    selectedTransactionTypes: storedFilters.selectedTransactionTypes,
    createdAtFilter: storedFilters.createdAtFilter,
    closingDateFilter: storedFilters.closingDateFilter,
    updatedAtFilter: storedFilters.updatedAtFilter || { gte: '', lte: '' },
  });

  console.log(filters, 'Newly filters');

  const [activeFiltersCount, setActiveFiltersCount] = useState(() => {
    let count = 0;
    if (storedFilters.selectedStatuses.length > 0) count++;
    if (storedFilters.createdAtFilter.gte && storedFilters.createdAtFilter.lte)
      count++;
    if (
      storedFilters.closingDateFilter.gte &&
      storedFilters.closingDateFilter.lte
    )
      count++;
    if (storedFilters.selectedTransactionTypes.length > 0) count++;
    return count;
  });

  // Form data state
  const defaultAddress = {
    unitNumber: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    placeId: '',
  };

  const [formData, setFormData] = useState({
    propertyAddress: { value: '', label: '' },
    selectedAgentName: { value: '', label: '' },
    selectedDocumentType: { value: '', label: '' },
    selectedOffice: { value: '', label: '' },
    address: defaultAddress,
  });

  const debouncedAgentSearchTerm =
    useDebounce(formData?.selectedAgentName?.label, 500) || '';

  useEffect(() => {
    fetchAgents();
  }, [debouncedAgentSearchTerm]);

  // Handlers
  const handleFilterChange = useCallback(
    (
      statuses: string[],
      createdAt: { gte: string; lte: string },
      closingDate: { gte: string; lte: string },
      transactionTypes: string[],
      updatedAt: { gte: string; lte: string }
    ) => {
      const newFilters = {
        selectedStatuses: statuses,
        selectedTransactionTypes: transactionTypes,
        createdAtFilter: createdAt,
        closingDateFilter: closingDate,
        updatedAtFilter: updatedAt,
      };

      setFilters(newFilters);
      filterHandler.storeFilters({
        ...newFilters,
        statusLabels: [],
      });

      let count = 0;
      if (statuses.length > 0) count++;
      if (createdAt.gte && createdAt.lte) count++;
      if (closingDate.gte && closingDate.lte) count++;
      if (transactionTypes.length > 0) count++;
      if (updatedAt.gte && updatedAt.lte) count++;
      setActiveFiltersCount(count);
    },
    []
  );

  const handleClearFilters = useCallback(() => {
    const clearedFilters = {
      selectedStatuses: [],
      selectedTransactionTypes: [],
      createdAtFilter: { gte: '', lte: '' },
      closingDateFilter: { gte: '', lte: '' },
      updatedAtFilter: { gte: '', lte: '' },
    };
    setFilters(clearedFilters);
    filterHandler.clearFilters();
    setActiveFiltersCount(0);
  }, []);

  const getTransactions = useCallback(
    (showLoading: boolean = true) => {
      if (showLoading) {
        setLoading(true);
      } else {
        setProgressBarLoading(true);
      }

      const transactionBuilder = new TransactionBuilder.builder()
        .setPage(currentPage)
        .setLimit(pageSize)
        .setStatus(filters.selectedStatuses)
        .setCreatedAtGte(filters.createdAtFilter.gte)
        .setCreatedAtLte(filters.createdAtFilter.lte)
        .setClosingDateGte(filters.closingDateFilter.gte)
        .setClosingDateLte(filters.closingDateFilter.lte)
        .setSearch(debouncedSearchValue)
        .setTransactionTypes(filters.selectedTransactionTypes)
        .setSort(sort)
        .setcomplianceTransactions(complianceTransactions)
        .build();

      transactionBuilder.fetchNext().then(
        (response) => {
          const transactionsWithKeys = response.data.map(
            (transaction: ComplianceTransaction) => ({
              ...transaction,
              key: transaction._id,
            })
          );
          setTransactions(transactionsWithKeys);
          setTotalTransactions(response.total);
          setCurrentPage(response.queryParams.page);
          setPageSize(response.queryParams.limit);
          setLoading(false);
          setProgressBarLoading(false);
          setInitialLoadComplete(true);
        },
        (error) => {
          console.error('Error fetching transactions:', error);
          message.error(`Error fetching transactions: ${error}`);
          setLoading(false);
          setProgressBarLoading(false);
        }
      );
    },
    [
      currentPage,
      pageSize,
      filters,
      debouncedSearchValue,
      sort,
      complianceTransactions,
    ]
  );

  const handlePaginationChange = useCallback(
    (page: number, newPageSize?: number) => {
      if (newPageSize && newPageSize !== pageSize) {
        setPageSize(newPageSize);
        setCurrentPage(1);
      } else if (page !== currentPage) {
        setCurrentPage(page);
      }
    },
    [currentPage, pageSize]
  );

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // Handle sorting
    if (sorter.field === 'closingDate') {
      setSort(sorter.order === 'ascend' ? 'closingDate' : '-closingDate');
    } else if (sorter.field === 'updatedAt') {
      setSort(sorter.order === 'ascend' ? 'updatedAt' : '-updatedAt');
    } else if (!sorter.order) {
      setSort('-createdAt');
    }

    // Handle pagination
    handlePaginationChange(pagination.current, pagination.pageSize);
  };

  // Effects
  useEffect(() => {
    const showLoading = !initialLoadComplete;
    getTransactions(showLoading);
  }, [getTransactions, initialLoadComplete]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const resetFormData = () => {
    setFormData({
      propertyAddress: { value: '', label: '' },
      selectedAgentName: { value: '', label: '' },
      selectedDocumentType: { value: '', label: '' },
      selectedOffice: { value: '', label: '' },
      address: defaultAddress,
    });
  };

  const handleFormSubmit = () => {
    addTransaction();
  };

  const fetchAgents = useCallback(async () => {
    setLoadingAgents(true);
    try {
      const response = await ComplianceService.getAgent(
        debouncedAgentSearchTerm
      );
      const agents = response?.data?.data.map((agent: any) => ({
        label: agent.name,
        value: agent._id,
      }));
      setAgentOptions(agents);
    } catch (error) {
      message.error('Failed to load agents');
    } finally {
      setLoadingAgents(false);
    }
  }, [debouncedAgentSearchTerm]);

  const handleSelect = (field: keyof typeof formData, option: any) => {
    setFormData({ ...formData, [field]: option });
  };

  const addTransaction = async () => {
    setButtonLoading(true);
    if (!formData.address.placeId) {
      message.error('Please select an address from dropdown');
      setButtonLoading(false);
      return;
    }
    try {
      const unitNumber = formData?.address?.unitNumber;
      const placeId = formData?.address?.placeId;
      const response: any = await AddressService.validateAddress(
        placeId,
        unitNumber,
        formData?.selectedAgentName?.value
      );
      if (response.status === 200) {
        console.log('Address validated successfully:', response.data);
        setIsModalOpen(false);
        setTransactionAlreadyExists(true);
        setAlreadyExisitingTransaction(response.data);
        return;
      }
    } catch (error) {
      console.error('Error validating address:', error);
    }
    ComplianceService.createTransaction(formData).then(
      (response) => {
        console.log('Transaction created:', response.data);
        setButtonLoading(false);
        setIsModalOpen(false);
        setResetKey((prevKey) => prevKey + 1);
        navigate(AppURLs.complianceDashboardTransaction(response?.data?._id));
      },
      (error) => {
        console.error('Error creating transaction:', error);
        setButtonLoading(false);
        message.error(`Error creating transaction: ${error}`);
      }
    );
  };

  const archiveTransaction = (transactionID: string) => {
    console.log('Archiving transaction:', transactionID);
    ComplianceService.archiveTransaction(transactionID).then((response) => {
      console.log('Transaction archived:', response.data);
      message.success('Transaction archived successfully');
    });
  };

  const adminProfile = getAdminProfile();
  const handleTransactionClick = (record: any) => {
    if (
      !adminProfile &&
      (record?.processingStatus?.status === 'PROCESSING' ||
        record?.processingStatus?.status === 'NOT_STARTED')
    ) {
      //  transaction is disabled
      return;
    }
    setTransactionSource(
      complianceTransactions ? 'compliance-transactions' : 'transactions'
    );
    navigate(AppURLs.complianceDashboardTransaction(record._id));
  };

  useEffect(() => {
    resetFormData();
    setResetKey((prevKey) => prevKey + 1);
  }, [isModalOpen]);

  useEffect(() => {
    const previousTransactionMode = sessionStorage.getItem(
      'isComplianceTransaction'
    );
    const currentTransactionMode = complianceTransactions ? 'true' : 'false';

    // Reset filters when switching between compliance and regular transactions
    if (previousTransactionMode !== currentTransactionMode) {
      sessionStorage.setItem('isComplianceTransaction', currentTransactionMode);

      // Initialize new filter handler with cleared state
      const freshFilterHandler = getTransactionFilterHandler();
      freshFilterHandler.clearFilters();

      // Reset all filter-related states
      setFilterHandler(() => freshFilterHandler);
      setStoredFilters(freshFilterHandler.getStoredFilters());
      setFilters({
        selectedStatuses: [],
        selectedTransactionTypes: [],
        createdAtFilter: { gte: '', lte: '' },
        closingDateFilter: { gte: '', lte: '' },
        updatedAtFilter: { gte: '', lte: '' },
      });
      setActiveFiltersCount(0);
      setSearchValue('');
    }
  }, [complianceTransactions]);

  const component = (
    <>
      {progressBarLoading && (
        <Box width="100%">
          <LoadingProgressBar />
        </Box>
      )}

      <Box style={{ padding: '1rem' }}>
        <AntdModal
          title={
            <Box display="flex" alignItems="center" gap={1}>
              <ExclamationCircleOutlined
                style={{ color: '#faad14', fontSize: '1.25rem' }}
              />
              <Typography fontSize="1.25rem" fontWeight={500}>
                Transaction Already Exists
              </Typography>
            </Box>
          }
          open={transactionAlreadyExists}
          onCancel={() => {
            setTransactionAlreadyExists(false);
            setAlreadyExisitingTransaction(null);
            setButtonLoading(false);
          }}
          footer={[
            <Button
              key="view"
              variant="contained"
              onClick={() => {
                window.open(
                  AppURLs.complianceDashboardTransaction(
                    alreadyExisitingTransaction?._id
                  ),
                  '_self'
                );
              }}
              sx={{ textTransform: 'none' }}
            >
              Go to transaction
            </Button>,
          ]}
        >
          <Typography>
            A transaction for this address already exists with the following
            details:
          </Typography>
          <Box
            mt={2}
            p={2}
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '4px',
            }}
          >
            <Typography>
              <b>Property Address</b> :{' '}
              {alreadyExisitingTransaction?.name || 'N/A'}
            </Typography>
          </Box>
        </AntdModal>

        <AntdModal
          title={
            <Typography fontSize="1.25rem" fontWeight={500} marginBottom={2}>
              Create Transaction
            </Typography>
          }
          open={isModalOpen}
          onCancel={handleCloseModal}
          onOk={handleFormSubmit}
          cancelText="Cancel"
          okText={buttonLoading ? 'Submitting...' : 'Submit'}
          okButtonProps={{ loading: buttonLoading }}
        >
          <AddressForm key={resetKey} query={''} setFormData={setFormData} />
          <Autocomplete
            key={`agent-${resetKey}`}
            options={agentOptions}
            getOptionLabel={(option) => option.label}
            loading={loadingAgents}
            style={{ marginTop: '1rem' }}
            onInputChange={(event, value) =>
              handleSelect('selectedAgentName', { label: value, value: '' })
            }
            value={formData.selectedAgentName}
            onChange={(event, value) =>
              handleSelect('selectedAgentName', value)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Select agent's name"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingAgents ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </AntdModal>

        <Box
          width="100%"
          padding="1rem"
          boxSizing="border-box"
          style={{ border: '1px solid #d8d8d8', borderRadius: '1rem' }}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize="2rem" fontWeight={500} fontFamily="Roboto">
              {complianceTransactions
                ? 'Compliance Transactions'
                : ' Transactions'}
            </Typography>

            {adminProfile ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                sx={{ textTransform: 'None' }}
              >
                Create Transaction
              </Button>
            ) : null}
          </Box>
          <Box
            width="100%"
            display="flex"
            paddingTop="0.5rem"
            gap="1rem"
            justifyContent="flex-end"
            paddingBottom="1rem"
          >
            <Input
              value={searchValue}
              size="large"
              placeholder="Search"
              suffix={
                searchValue === '' ? (
                  <IconButton
                    disabled={true}
                    sx={{
                      padding: '0.25rem',
                      color: 'black',
                      '&.Mui-disabled': {
                        color: 'black',
                      },
                    }}
                  >
                    <Search />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      setSearchValue('');
                    }}
                    sx={{
                      padding: '0.25rem',
                      color: 'black',
                      '&.Mui-disabled': {
                        color: 'black',
                      },
                    }}
                  >
                    <Close />
                  </IconButton>
                )
              }
              style={{ maxWidth: '25rem' }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <TransactionFilterComponent
              selectedStatuses={filters.selectedStatuses}
              selectedTransactionTypes={filters.selectedTransactionTypes}
              createdAtFilter={filters.createdAtFilter}
              closingDateFilter={filters.closingDateFilter}
              updatedAtFilter={filters.updatedAtFilter}
              onFilterChange={handleFilterChange}
              onClearFilters={handleClearFilters}
              activeFiltersCount={activeFiltersCount}
            />
          </Box>
          {transactions.length > 0 ? (
            <Box>
              <Table
                dataSource={transactions}
                style={{ cursor: 'pointer' }}
                onRow={(record) => ({
                  onClick: () => handleTransactionClick(record),
                  style:
                    !adminProfile &&
                    (record?.processingStatus?.status === 'PROCESSING' ||
                      record?.processingStatus?.status === 'NOT_STARTED')
                      ? {
                          cursor: 'not-allowed',
                          opacity: 0.7,
                        }
                      : undefined,
                })}
                loading={loading || progressBarLoading}
                onChange={handleTableChange}
                pagination={{
                  pageSize: pageSize,
                  total: totalTransactions,
                  current: currentPage,
                  showSizeChanger: true,
                }}
                components={{
                  body: {
                    row: (props: any) => {
                      const record = props?.children?.[0]?.props?.record;
                      if (
                        !adminProfile &&
                        record &&
                        (record?.processingStatus?.status === 'PROCESSING' ||
                          record?.processingStatus?.status === 'NOT_STARTED')
                      ) {
                        return (
                          <Tooltip
                            title="This transaction is under process"
                            placement="top"
                          >
                            <tr {...props} />
                          </Tooltip>
                        );
                      }
                      return <tr {...props} />;
                    },
                  },
                  header: {
                    cell: (props: any) => (
                      <th
                        {...props}
                        style={{
                          ...props.style,
                          background: 'rgba(216, 216, 216, 0.44)',
                        }}
                      />
                    ),
                  },
                }}
              >
                <Table.Column
                  title="Property Address"
                  dataIndex="name"
                  key="name"
                />
                <Table.Column
                  title={
                    getCompliancePartnerID() === '669a55c894721303b632d1d5'
                      ? 'Deal Number'
                      : 'Dotloop Link'
                  }
                  dataIndex="externalSourceID"
                  key="externalSourceID"
                  render={(_, record: any) => {
                    if (
                      getCompliancePartnerID() === '669a55c894721303b632d1d5'
                    ) {
                      // Show deal number with copy button for Keyes
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {record?.externalSourceID || '-'}
                          {record?.externalSourceID && (
                            <IconButton
                              size="small"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${record?.externalSourceID} ${record?.name || ''}`
                                );
                                message.success(
                                  'Deal number copied to clipboard'
                                );
                              }}
                              sx={{ ml: 1 }}
                            >
                              <ContentCopy sx={{ fontSize: 16 }} />
                            </IconButton>
                          )}
                        </Box>
                      );
                    } else {
                      // Show hyperlink for B/W
                      return record?.externalSourceID ? (
                        <a
                          href={`https://www.dotloop.com/m/loop?viewId=${record.externalSourceID}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {record?.externalSourceID}
                        </a>
                      ) : (
                        '-'
                      );
                    }
                  }}
                />
                <Table.Column
                  title="Transaction Type"
                  dataIndex="type"
                  key="type"
                  render={(type: string) => TRANSACTION_TYPE_OPTIONS(type)}
                />
                <Table.Column
                  title="Milestone"
                  dataIndex="milestone"
                  key="milestone"
                  render={(milestone: string) => formatString(milestone)}
                />
                <Table.Column
                  title="Status"
                  dataIndex="processingStatus"
                  key="processingStatus"
                  render={(processingStatus: any) =>
                    transactionProcessingStatusMap?.[processingStatus?.status]
                      ?.name || formatString(processingStatus?.status)
                  }
                />
                <Table.Column
                  title="Closing Date"
                  dataIndex="closingDate"
                  key="closingDate"
                  sorter={true}
                  sortDirections={['ascend', 'descend']}
                  render={(closingDate: any) =>
                    closingDate ? getMMDDYYYYString(closingDate) : '-'
                  }
                />
                <Table.Column
                  title="Last Updated"
                  dataIndex="updatedAt"
                  key="updatedAt"
                  sorter={true}
                  sortDirections={['ascend', 'descend']}
                  render={(updatedAt: string) =>
                    updatedAt ? getMMDDYYYYString(updatedAt) : '-'
                  }
                />
                <Table.Column
                  title="Delete"
                  dataIndex="_id"
                  key="_id"
                  render={(transactionID: string) => {
                    return (
                      <Popconfirm
                        title="Delete Transaction"
                        description="Are you sure to delete this transaction?"
                        onConfirm={(e) => {
                          archiveTransaction(transactionID);
                          e?.stopPropagation();
                        }}
                        onCancel={(e) => {
                          e?.stopPropagation();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <IconButton
                          sx={{
                            marginLeft: 'auto',
                            marginBottom: 'auto',
                            color: 'black',
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Delete />
                        </IconButton>
                      </Popconfirm>
                    );
                  }}
                />
              </Table>
            </Box>
          ) : (
            <Box width="100%" padding="5rem" boxSizing="border-box">
              <Empty description="No Transactions Found!" />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );

  return (
    <>
      <Box width="100%" boxSizing="border-box">
        <WaitAndLoadingWithSpinner loading={loading} component={component} />
      </Box>
    </>
  );
};

export default ComplianceTransactionsPage;
