import 'react-quill/dist/quill.snow.css';

import { Box, Typography } from '@mui/material';
import { Button, Input, Modal, Select, message } from 'antd';
import {
  filterValidEmails,
  getInitialReplyToOptionsForPartner,
  getTemplateForPartner,
} from 'app/utils/email';
import {
  getCompliancePartnerID,
  getComplianceProfile,
} from 'app/utils/localStorageHandler/userProfile';
import { useEffect, useState } from 'react';

import { ComplianceService } from 'app/services/compliance';
import { ComplianceTransaction } from 'app/interfaces/ComplianceTransaction';
import { ErrorField } from 'app/interfaces/Document';
import ReactQuill from 'react-quill';
import { documentNameMap } from 'app/configs/documentNameMap';

const EmailPreviewModal = ({
  open,
  onCancel,
  errors,
  errorDocumentIdsAndActionList,
  transactionData,
}: {
  open: boolean;
  onCancel: any;
  errors: { [key: string]: ErrorField[] | string[] };
  errorDocumentIdsAndActionList: Record<string, Record<string, string>[]>;
  transactionData: ComplianceTransaction;
}) => {
  const [emailContent, setEmailContent] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [replyToOptions, setReplyToOptions] = useState<
    Record<string, string>[]
  >([]);
  const [toOptions, setToOptions] = useState<string[]>([]);
  const [formData, setFormData] = useState({
    emailBody: '',
    recipients: {
      replyTo: {
        name: '',
        email: '',
      },
      to: [] as string[],
      bcc: [] as string[],
    },
  });

  const updateFormData = (field: string, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const updateRecipients = (
    type: 'replyTo' | 'to' | 'bcc',
    value: string[] | { name: string; email: string }
  ) => {
    console.log('updateRecipients -> type', type, value);
    setFormData((prev) => ({
      ...prev,
      recipients: {
        ...prev.recipients,
        [type]:
          type === 'replyTo'
            ? {
                name: (value as { name: string; email: string }).name,
                email: (value as { name: string; email: string }).email,
              }
            : value,
      },
    }));
  };

  useEffect(() => {
    setupEmailPreview();
  }, [transactionData, errors]);

  const setupEmailPreview = () => {
    const template = getTemplateForPartner();

    if (!template) {
      Modal.error({
        title: 'Error',
        content: 'Email feature is not available. Contact support.',
      });
      return;
    }

    fetch(template)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((data) => setEmailContent(data))
      .catch((error) => console.error('Error fetching email template:', error));

    // Prepopulate the 'replyTo' options
    // TODO: Move this to a config which the backend sends  - do it when adding support for Keyes
    let initialReplyToOptions = getInitialReplyToOptionsForPartner();
    const complianceProfileName = getComplianceProfile()?.name;
    const complianceProfileEmail = getComplianceProfile()?.email;
    if (complianceProfileName && complianceProfileEmail) {
      initialReplyToOptions = [
        ...initialReplyToOptions,
        {
          name: complianceProfileName,
          email: complianceProfileEmail,
        },
      ];
    }
    console.log('Initial ReplyTo Options before: ', initialReplyToOptions);
    setReplyToOptions(initialReplyToOptions);
    updateRecipients('replyTo', initialReplyToOptions[0]);
    updateRecipients('bcc', [initialReplyToOptions[0].email]);

    // Set the subject to `Action Needed: Review and Rectify Documents for ${transactionData.name}`
    setSubject(
      `Action Needed: Review and Rectify Documents for ${transactionData?.externalSourceID && getCompliancePartnerID() === '669a55c894721303b632d1d5' ? `${transactionData?.externalSourceID} ` : ''}${transactionData.name}`
    );

    // Set the 'to' field to all the agent emails in the transaction
    const agentEmails = transactionData?.agents
      ? transactionData.agents.map((agent) => agent.details.email)
      : [];

    updateRecipients('to', agentEmails);
    setToOptions(agentEmails);

    if (!transactionData) return;
    if (!errors) return;

    // Iterate through the errors and generate the email body
    let body = `<p>Hello ${transactionData?.agents?.[0]?.details.name},</p>
      <p>This is a reminder for you to review and rectify the documents for <b>${transactionData?.externalSourceID && getCompliancePartnerID() === '669a55c894721303b632d1d5' ? `${transactionData?.externalSourceID} ` : ''}${transactionData.name}</b>.</p>`;
    console.log('Errors inside EmailPreviewModal: ', errors);
    // First handle _missingDocuments if it exists
    if ('_missingDocuments' in errors) {
      body += `<p>The following documents are missing from the transaction:</p>`;
      body += `<ul>`;
      (errors['_missingDocuments'] as string[]).forEach(
        (missingDocumentType) => {
          body += `<li>${documentNameMap[missingDocumentType]}</li>`;
        }
      );
      body += '</ul>';
    }

    if (
      Object.keys(errors).filter((key) => key !== '_missingDocuments').length >
      0
    ) {
      body += `<br/><p>The following documents need to be reviewed:</p>`;

      // Then handle all other document types
      let counter = 1;
      Object.keys(errors)
        .filter((key) => key !== '_missingDocuments')
        .forEach((documentType) => {
          body += `<p><b>${counter}. ${documentNameMap[documentType]}</b></p>`;
          body += `<ul>`;
          (errors[documentType] as ErrorField[]).forEach((error) => {
            const pageInfo = error.pages?.length
              ? `Page ${error.pages.join(',')}: `
              : '';
            body += `<li>${pageInfo}${error.errorTitle} - ${error.name}</li>`;
          });
          body += '</ul>';
          counter++;
        });
    }

    if (getCompliancePartnerID() === '669a55c894721303b632d1d5') {
      body += `
      <p>Please review and rectify the errors at your earliest convenience.</p>
      <p>Regards,<br>Keyes Transaction Team</p>`;
    } else {
      body += `
      <p>Please review and rectify the errors before the deadline.</p>
      <p>Regards,<br>Compliance Team</p>`;
    }

    updateFormData('emailBody', body);
  };

  const sendEmail = () => {
    ComplianceService.sendCSTeamRectificationEmail(
      transactionData._id,
      errorDocumentIdsAndActionList,
      subject,
      formData.emailBody,
      formData.recipients
    )
      .then(() => {
        console.log('Email sent successfully');
        Modal.success({
          title: 'Email Sent',
          content: 'The email has been sent successfully.',
          onOk: () => {
            close();
          },
        });
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        message.error('Error sending email');
      });
  };

  const close = () => {
    onCancel();
    updateFormData('emailBody', '');
    setSubject('');
    updateRecipients('replyTo', {
      name: '',
      email: '',
    });
    updateRecipients('to', []);
    updateRecipients('bcc', []);

    // Also reload the page
    window.location.reload();
  };

  const reset = () => {
    console.log('Resetting email preview');
    setupEmailPreview();
  };

  return (
    <Modal
      title={
        <Typography fontSize="2rem" fontWeight="500" padding="0.5rem">
          Email Preview
        </Typography>
      }
      centered
      open={open}
      onCancel={onCancel}
      width="85vw"
      footer={[
        <div key="footer">
          <Button
            danger
            type="primary"
            onClick={() => reset()}
            style={{ marginRight: '0.5rem' }}
          >
            Reset
          </Button>
          <Button onClick={onCancel} style={{ marginRight: '0.5rem' }}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => sendEmail()}
            disabled={
              !formData.recipients.replyTo ||
              formData.recipients.to.length === 0 ||
              !subject ||
              !formData.emailBody ||
              formData.emailBody === '' ||
              formData.emailBody.length <= 50
            }
          >
            Send Email
          </Button>
        </div>,
      ]}
    >
      <Box display="flex" gap={2} width="100%" sx={{ position: 'relative' }}>
        <div
          style={{
            flex: '2',
            minWidth: 0,
            width: '100%',
            paddingRight: '0.5rem',
            maxHeight: 'calc(90vh - 108px)',
            overflowY: 'auto',
          }}
          dangerouslySetInnerHTML={{
            __html: emailContent.replace(
              '{{trigger.content.body}}',
              formData.emailBody
            ),
          }}
        />
        <div
          style={{
            flex: '1',
            minWidth: 0,
            width: '100%',
            height: 'calc(90vh - 108px)',
            overflowY: 'auto',
            boxSizing: 'border-box',
            borderRadius: '8px',
            paddingRight: '0.5rem',
          }}
        >
          <Box
            style={{
              backgroundColor: '#F0F3F5',
              padding: '1rem',
              borderRadius: '8px',
            }}
          >
            <div style={{ marginBottom: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '0.5rem',
                }}
              >
                <span style={{ width: '60px', marginRight: '8px' }}>From:</span>
                <Select
                  style={{ width: '100%' }}
                  value={formData.recipients.replyTo.name}
                  onChange={(value, option) => {
                    const selectedOption = Array.isArray(option)
                      ? option[0]
                      : option;
                    updateRecipients('replyTo', {
                      name: selectedOption.label,
                      email: selectedOption.value,
                    });
                  }}
                  options={[
                    ...replyToOptions.map((option) => ({
                      value: option.email,
                      label: option.name,
                    })),
                  ]}
                  showSearch
                  allowClear
                  listItemHeight={24}
                  listHeight={250}
                  optionLabelProp="label"
                  dropdownStyle={{ width: '300px' }}
                  optionRender={(option) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>
                        <b>{option.label}</b> <br />
                        {option.value}
                      </Box>
                    </div>
                  )}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '0.5rem',
                }}
              >
                <span style={{ width: '60px', marginRight: '8px' }}>To:</span>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  tokenSeparators={[',']}
                  onChange={(values) => {
                    const validEmails: string[] = filterValidEmails(values);
                    updateRecipients('to', validEmails);
                  }}
                  value={formData.recipients.to}
                  options={[
                    ...toOptions.map((email) => ({
                      value: email,
                      label: email,
                    })),
                  ]}
                  optionRender={(option) => (
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {option.label}
                    </div>
                  )}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '0.5rem',
                }}
              >
                <span style={{ width: '60px', marginRight: '8px' }}>BCC:</span>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  tokenSeparators={[',']}
                  onChange={(values) => {
                    const validEmails = filterValidEmails(values);
                    updateRecipients('bcc', validEmails);
                  }}
                  value={formData.recipients.bcc}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '0.5rem',
                }}
              >
                <span style={{ width: '60px', marginRight: '8px' }}>
                  Subject:
                </span>
                <Input
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                  value={subject}
                />
              </div>
            </div>
            <div>
              <ReactQuill
                value={formData.emailBody}
                onChange={(content) => updateFormData('emailBody', content)}
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['clean'],
                  ],
                }}
                style={{
                  backgroundColor: 'white',
                  maxHeight: 'calc(100% - 42px - 135px)', // 42px for toolbar, 132px for inputs
                }}
              />
            </div>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default EmailPreviewModal;
