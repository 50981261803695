import React, { useState } from 'react';
import { Modal, Typography, Button, Tooltip, Input, message } from 'antd';
import {
  EditOutlined,
  UserOutlined,
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { TaskService } from '../services';
import '../styles/TaskPreviewModal.scss';
import { AppURLs } from 'app/utils/appURLs';
import { getCustomIdFromUrl } from 'app/utils/url';

const { Text } = Typography;

interface TaskPreviewModalProps {
  task: any;
  onClose: () => void;
}

const TaskPreviewModal: React.FC<TaskPreviewModalProps> = ({
  task,
  onClose,
}) => {
  const [editingSubject, setEditingSubject] = useState(false);
  const [editingRecipients, setEditingRecipients] = useState(false);
  const [recipients, setRecipients] = useState<string[]>(
    task?.recipient?.filter(Boolean) || []
  );
  const [subject, setSubject] = useState(task?.emailSubject);
  const [loading, setLoading] = useState(false);

  const handleSaveRecipients = async () => {
    setLoading(true);
    try {
      const updatedRecipients = recipients.filter(Boolean);
      await TaskService.updateTaskRecipients(task._id, updatedRecipients);
      setEditingRecipients(false);
      message.success('Recipients updated successfully');
    } catch (error) {
      message.error('Failed to update recipients');
    } finally {
      setLoading(false);
    }
  };

  const handleRecipientsChange = (value: string) => {
    const emailList = value.split(',').map((email) => email.trim());
    // Remove the special handling of primary recipient
    setRecipients(emailList);
  };

  const handleSaveSubject = async () => {
    setLoading(true);
    try {
      await TaskService.updateTaskMetadata(task._id, { subject: subject });
      setEditingSubject(false);
      message.success('Subject updated successfully');
    } catch (error) {
      message.error('Failed to update subject');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={task.name}
      open={true}
      onCancel={onClose}
      width={800}
      footer={null}
      className="task-preview-modal"
    >
      <div className="task-preview-modal__content">
        {task?.recipient && (
          <div className="task-preview-modal__email-header">
            <div className="header-row">
              <Text strong>Recipients:</Text>
              {editingRecipients ? (
                <div className="edit-field">
                  <Input
                    value={recipients.join(', ')}
                    onChange={(e) => handleRecipientsChange(e.target.value)}
                    placeholder="Enter email addresses separated by commas"
                    disabled={false}
                  />
                  <Button
                    type="text"
                    icon={<CheckOutlined />}
                    onClick={handleSaveRecipients}
                    loading={loading}
                    className="action-button save"
                  />
                  <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      setEditingRecipients(false);
                      setRecipients(task?.recipient?.filter(Boolean) || []);
                    }}
                    className="action-button cancel"
                  />
                </div>
              ) : (
                <>
                  <Text>{recipients.join(', ')}</Text>
                  <Tooltip title="Edit Recipients">
                    <Button
                      type="text"
                      icon={<UserOutlined />}
                      onClick={() => setEditingRecipients(true)}
                      className="edit-button"
                    />
                  </Tooltip>
                </>
              )}
            </div>
            {task.emailSubject && (
              <div className="header-row">
                <Text strong>Subject:</Text>
                {editingSubject ? (
                  <div className="edit-field">
                    <Input
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      placeholder="Enter email subject"
                    />
                    <Button
                      type="text"
                      icon={<CheckOutlined />}
                      onClick={handleSaveSubject}
                      loading={loading}
                      className="action-button save"
                    />
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={() => {
                        setEditingSubject(false);
                        setSubject(task?.emailSubject);
                      }}
                      className="action-button cancel"
                    />
                  </div>
                ) : (
                  <>
                    <Text>{subject}</Text>
                    <Tooltip title="Edit Subject">
                      <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={() => setEditingSubject(true)}
                        className="edit-button"
                      />
                    </Tooltip>
                  </>
                )}
              </div>
            )}
          </div>
        )}

        {task?.htmlContent && (
          <div className="task-preview-modal__html-content">
            <div
              className="html-content-wrapper"
              dangerouslySetInnerHTML={{ __html: task?.htmlContent }}
            />
          </div>
        )}

        {task?.taskErrors && Object.keys(task.taskErrors).length > 0 && (
          <div className="task-preview-modal__section">
            <div className="section-header">
              <Typography.Title level={4} style={{ margin: 0 }}>
                Task Errors
                <a
                  href={AppURLs.complianceDashboardTransactionFacts(
                    getCustomIdFromUrl('transactionID')
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="navigate-link"
                >
                  <ArrowRightOutlined />
                </a>
              </Typography.Title>
            </div>
            <div className="task-preview-modal__error">
              {Object.values(task.taskErrors).map(
                (error: any, index: number) => (
                  <div key={index} className="error-item">
                    <ExclamationCircleOutlined className="error-icon" />
                    {error?.description}
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TaskPreviewModal;
