import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Collapse,
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
  Edit,
  FolderCopyOutlined,
  CloseOutlined,
} from '@mui/icons-material';
import './KeyTransactions.scss';
import {
  Breadcrumb,
  Input,
  List,
  message,
  Spin,
  Steps,
  Radio,
  Popconfirm,
  Empty,
} from 'antd';
import { ResponsiveRenderer } from 'app/utils/super-components/responsiveRenderer';
import moment from 'moment';
import { ComplianceService } from 'app/services/compliance';
import { getCustomIdFromUrl } from 'app/utils/url';
import { documentNameMap } from 'app/configs/documentNameMap';
import { getMMDDYYYYString, getDayNameAndDate } from 'app/utils/datetime';
// import { sortSectionsByPriority, subGroupMap } from '../utils/mapUtil';
import { AgentService } from 'app/services/agent';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import { AppURLs } from 'app/utils/appURLs';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Typography as AntTypography,
  Space,
  Table as AntTable,
} from 'antd';
import {
  WarningOutlined,
  DownOutlined,
  UpOutlined,
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  sortSectionsByPriority,
  subGroupMap,
} from 'app/pages/Compliance/Dashboard/pages/utils/mapUtil';
import { compareValues } from '../../utils/comparator';
import { getAdminProfile } from 'app/utils/localStorageHandler/userProfile';
import { useRecoilValue } from 'recoil';
import { transactionSourceAtom } from 'app/atomManagers/transactionSourceAtom';

const { Text, Link } = AntTypography;

const TransactionKeyFacts: React.FC = () => {
  const navigate = useNavigate();
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState<any>(null);
  const [newValue, setNewValue] = useState<any>(null);
  const [items, setItems] = useState<any>({});
  const [transactionTimeline, setTransactionTimeline] = useState<any>({});
  const [transaction, setTransaction] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});
  const [isApproved, setIsApproved] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const transactionID = getCustomIdFromUrl('transactionID');
  const transactionSource = useRecoilValue(transactionSourceAtom);
  const handleApprove = async () => {
    setButtonLoading(true);
    try {
      const response =
        await ComplianceService.approveTransaction(transactionID);
      if (response.status === 200) {
        setIsApproved(true);
        message.success('Details approved successfully!');
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setButtonLoading(false);
    }
  };

  const toggleDetails = (rowIndex: string) => {
    setOpenRows((prev: Record<string, boolean>) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const handleEdit = (item: any, key: string) => {
    setEditingItem({ ...item, key });
    if (item.type === 'date') {
      const formattedDate = moment(item.values[0].value, 'YYYY-MM-DD').format(
        'MM-DD-YYYY'
      );
      setNewValue(formattedDate);
    } else {
      setNewValue(item?.values[0]?.value);
    }
  };

  const handleSubmit = async (item: any, key: string, newValue: any) => {
    const currentValue = item?.values[0]?.value;
    if (compareValues(currentValue, newValue)) {
      message.warning('Change existing value and submit');
      return;
    }

    if (editingItem || item.type === 'boolean') {
      setEditLoading(true);
      let response;
      try {
        response = await ComplianceService.updateSourceOfTruth(
          transactionID,
          key,
          newValue
        );

        if (response.status === 200) {
          // change value of this item in items dictionary
          const updatedValueFromResponse = response?.data?.data[key];
          setItems({
            ...items,
            [item.group]: {
              ...items[item.group],
              [key]: {
                ...updatedValueFromResponse,
              },
            },
          });
          message.success('Value updated successfully!');
        }
      } catch (error: any) {
        message.error(error.message);
      } finally {
        setEditLoading(false);
        setEditingItem(null);
        setNewValue(null);
      }
    }
  };

  const renderInputField = (item: any, key: string) => (
    <Spin spinning={editLoading}>
      <Input
        value={newValue}
        onChange={(e: any) => setNewValue(e.target.value)}
        onPressEnter={() => handleSubmit(item, key, newValue)}
        disabled={editLoading}
      />
      <IconButton
        onClick={() => handleSubmit(item, key, newValue)}
        disabled={editLoading}
      >
        <CheckOutlined style={{ fontSize: '1rem' }} />
      </IconButton>
      <IconButton
        onClick={() => {
          setEditingItem(null);
          setNewValue(null);
        }}
        disabled={editLoading}
      >
        <CloseOutlined style={{ fontSize: '1rem' }} />
      </IconButton>
    </Spin>
  );

  const renderDatePicker = (item: any, key: string) => (
    <Spin spinning={editLoading}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={newValue ? dayjs(newValue) : null}
          onChange={(date: any) => {
            if (date) {
              const formattedDate = date.format('MM-DD-YYYY');
              setNewValue(formattedDate);
            }
          }}
          onAccept={async (date: any) => {
            if (date) {
              const formattedDate = date.format('MM-DD-YYYY');
              setNewValue(formattedDate);
              const formattedDateForSubmit = date.format('YYYY-MM-DD');
              await handleSubmit(item, key, formattedDateForSubmit);
            }
          }}
          format="MM-DD-YYYY"
          slotProps={{
            textField: {
              size: 'small',
            },
          }}
        />
      </LocalizationProvider>
      <IconButton
        onClick={() => {
          setEditingItem(null);
          setNewValue(null);
        }}
        disabled={editLoading}
      >
        <CloseOutlined style={{ fontSize: '1rem' }} />
      </IconButton>
    </Spin>
  );

  const renderListItems = (item: any) => {
    if (!item?.values[0]?.value) {
      return null;
    }
    return (
      <List
        size="small"
        dataSource={item?.values[0]?.value}
        renderItem={(value: string) => <List.Item>{value}</List.Item>}
      />
    );
  };

  const handleListItemChange = (index: number, newVal: string) => {
    const updatedValues = [...newValue]; // copy existing array
    updatedValues[index] = newVal; // update value
    setNewValue(updatedValues);
  };

  const renderListEditor = (item: any, key: string) => {
    return (
      <Spin spinning={editLoading}>
        <List
          size="small"
          dataSource={newValue}
          renderItem={(value: string, index: number) => (
            <List.Item
              style={{
                padding: '0px',
                paddingTop: '8px',
                paddingBottom: '8px',
              }}
            >
              <Space>
                <Input
                  value={value}
                  onChange={(e) => handleListItemChange(index, e.target.value)}
                  disabled={editLoading}
                />
                <IconButton
                  onClick={() => {
                    const updatedValues = newValue.filter(
                      (_: any, i: number) => i !== index
                    );
                    setNewValue(updatedValues);
                  }}
                  disabled={editLoading}
                >
                  <DeleteOutlined style={{ fontSize: '1rem' }} />
                </IconButton>
              </Space>
            </List.Item>
          )}
          footer={
            <Button
              type="dashed"
              onClick={() => setNewValue([...newValue, ''])}
              block
              icon={<PlusOutlined />}
              disabled={editLoading}
              style={{ width: 'fit-content' }}
            >
              Add Item
            </Button>
          }
        />
        <Space style={{ marginTop: '8px' }}>
          <IconButton
            onClick={() => handleSubmit(item, key, newValue)}
            disabled={editLoading}
          >
            <CheckOutlined style={{ fontSize: '1rem' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditingItem(null);
              setNewValue(null);
            }}
            disabled={editLoading}
          >
            <CloseOutlined style={{ fontSize: '1rem' }} />
          </IconButton>
        </Space>
      </Spin>
    );
  };

  const renderValueText = (item: any, key: string) => {
    if (item.type === 'list') {
      return renderListItems(item);
    } else if (item.type === 'boolean') {
      return renderBooleanValue(item, key);
    } else if (item.type === 'date') {
      // convert YYYY-MM-DD to MM-DD-YYYY format
      const formattedDate = moment(item.values[0].value, 'YYYY-MM-DD').format(
        'MM-DD-YYYY'
      );
      return formattedDate;
    } else {
      return item?.values[0]?.value || 'N/A';
    }
  };

  const renderBooleanValue = (item: any, key: string) => {
    return (
      <Spin spinning={loading}>
        <Radio.Group
          value={item?.values[0]?.value}
          className="custom-radio-group"
        >
          <Popconfirm
            title="Confirm Edit"
            description="Are you sure you want to edit this value to YES?"
            onConfirm={() => handleSubmit(item, key, true)}
            onCancel={() => setEditingItem(null)}
            okText="Submit"
            cancelText="Cancel"
          >
            <Radio value={true}>Yes</Radio>
          </Popconfirm>
          <Popconfirm
            title="Confirm Edit"
            description="Are you sure you want to edit this value to NO?"
            onConfirm={() => handleSubmit(item, key, false)}
            onCancel={() => setEditingItem(null)}
            okText="Submit"
            cancelText="Cancel"
          >
            <Radio value={false}>No</Radio>
          </Popconfirm>
        </Radio.Group>
      </Spin>
    );
  };

  const renderItemValue = (item: any, key: string) => {
    if (editingItem?.title === item?.title) {
      if (item?.type === 'string' || item?.type === 'number') {
        return renderInputField(item, key); // Render input field for string and number type
      } else if (item.type === 'list') {
        return renderListEditor(item, key); // Render list editor for list type
      } else if (item?.type === 'date') {
        return renderDatePicker(item, key); // Render date picker for date type
      }
    }
    return (
      <>
        {renderValueText(item, key)}
        {(item.type === 'string' ||
          item.type === 'number' ||
          item.type === 'list' ||
          item.type === 'date') && (
          <IconButton size="small" onClick={() => handleEdit(item, key)}>
            <Edit fontSize="small" />
          </IconButton>
        )}
      </>
    );
  };

  const getSignedDocumentUrl = async (documentId: string) => {
    const response = await AgentService.getDocument(documentId);
    if (response.status === 200) {
      return response?.data?.signedUrl;
    } else {
      return message.error('Document not found');
    }
  };

  const renderManualUpdateValue = (
    item: any,
    fontSize: string = '0.875rem'
  ) => {
    if (item?.source === 'MANUAL_UPDATE') {
      return (
        <Text italic style={{ color: '#666', fontSize: fontSize }}>
          Manually edited on {getMMDDYYYYString(item?.timestamp)}
        </Text>
      );
    }
    return null;
  };

  const renderContractValue = (item: any, fontSize: string = '0.875rem') => {
    const documentName = documentNameMap[item?.source];
    if (item?.source !== 'MANUAL_UPDATE')
      return (
        <Link
          onClick={() => handleDocumentClick(item?.documentId)}
          style={{ fontSize: fontSize }}
        >
          From {documentName}
        </Link>
      );
    return null;
  };

  const handleDocumentClick = async (documentId: string) => {
    const signedUrl = await getSignedDocumentUrl(documentId);
    if (signedUrl) {
      window.open(signedUrl, '_blank');
    }
  };

  const renderItemSourceAndLogs = (item: any, index: number) => {
    const columns = [
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        render: (text: any) => (
          <Typography style={{ fontSize: '0.75rem' }}>
            {item.type === 'boolean'
              ? text
                ? 'Yes'
                : 'No'
              : item.type === 'list'
                ? text.join(', ')
                : item.type === 'date'
                  ? moment(text, 'YYYY-MM-DD').format('MM-DD-YYYY')
                  : text}
          </Typography>
        ),
      },
      {
        title: 'Source',
        key: 'source',
        render: (record: any) => (
          <>
            {renderContractValue(record, '0.75rem')}
            {renderManualUpdateValue(record, '0.75rem')}
          </>
        ),
      },
    ];

    return (
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        <Space direction="vertical">
          {renderManualUpdateValue(item?.values[0], '0.875rem')}
          {renderContractValue(item?.values[0], '0.875rem')}
          {item.values.length > 1 && (
            <Space>
              <WarningOutlined style={{ color: 'red' }} />
              <Text>{item.values.length} documents w/ different data</Text>
            </Space>
          )}
        </Space>
        {item.values.length > 1 && (
          <>
            <Button
              type="link"
              onClick={() => toggleDetails(item.title)}
              style={{ paddingLeft: 0 }}
              icon={openRows[item.title] ? <UpOutlined /> : <DownOutlined />}
            >
              See details
            </Button>
            {openRows[item.title] && (
              <AntTable
                columns={columns}
                dataSource={item.values}
                size="small"
                pagination={false}
                showHeader={false}
              />
            )}
          </>
        )}
      </Space>
    );
  };

  const renderSubGroups = (items: any) => (
    <Table>
      <TableBody>
        {Object.entries(items).length > 0
          ? Object.entries(items).map(
              ([key, item]: [string, any], index: number) => (
                <React.Fragment key={item?.title}>
                  <TableRow className="table-row">
                    <TableCell className="label cell-width-30">
                      {item?.title}
                    </TableCell>
                    <TableCell className="value">
                      {renderItemValue(item, key)}
                      {/* Call the modular function */}
                    </TableCell>
                    {/* <TableCell className="source cell-width-45">
                      <Typography variant="caption" color="textSecondary">
                        {item?.values?.length > 0
                          ? renderItemSourceAndLogs(item, index)
                          : 'NA'}
                      </Typography>
                    </TableCell> */}
                  </TableRow>
                  {item.warning && (
                    <TableRow>
                      <TableCell colSpan={4} className="warning">
                        <Typography variant="caption" color="error">
                          {item.warning}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {index < Object.values(items).length - 1 && (
                    <TableRow>
                      <TableCell
                        colSpan={Object.values(items).length}
                        className="divider"
                      >
                        <Divider />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              )
            )
          : null}
      </TableBody>
    </Table>
  );

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const toggleSection = (section: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };
  const goToTransactions = () => {
    if (transactionSource === 'compliance-transactions') {
      navigate(AppURLs.complianceDashboardComplianceTransactions());
    } else {
      navigate(AppURLs.complianceDashboardTransactions());
    }
  };

  const adminProfile = getAdminProfile();

  const sectionList = sortSectionsByPriority(
    Object.entries(items)
      .filter(([key]) => {
        // Show all sections including 'Others' when adminProfile exists
        // Hide 'Others' when adminProfile doesn't exist
        return adminProfile || key !== 'Others';
      })
      .map(([key, value]) => ({
        label: subGroupMap[key as keyof typeof subGroupMap] || key,
        content: renderSubGroups(value),
      }))
  );

  const keyTransactionEvents = (isMobile: boolean) => {
    return Object.keys(transactionTimeline).length > 0 ? (
      <Box className="right-column">
        <Box
          style={
            isMobile
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }
              : {}
          }
        >
          <Typography variant="h6">Key Transaction Events</Typography>
          {isMobile ? (
            <IconButton onClick={() => handleCloseModal()}>
              <CloseOutlined />
            </IconButton>
          ) : null}
        </Box>
        <Steps
          progressDot
          direction="vertical"
          current={Object.keys(transactionTimeline).length - 1}
          items={Object.entries(transactionTimeline)
            .sort(([, a]: [string, any], [, b]: [string, any]) => {
              const dateA = new Date(a.values[0].value);
              const dateB = new Date(b.values[0].value);
              return dateA.getTime() - dateB.getTime();
            })
            .map(([key, event]: [string, any]) => ({
              title: (
                <span style={{ fontSize: '0.875rem' }}>
                  {getDayNameAndDate(event?.values[0]?.value)}
                </span>
              ),
              description: (
                <span style={{ fontSize: '0.875rem' }}>{event?.title}</span>
              ),
            }))}
        />
      </Box>
    ) : null;
  };

  const keyTransactionEventsMobile = () => (
    <Box
      padding="1rem"
      style={{
        backgroundColor: 'white',
      }}
    >
      {keyTransactionEvents(true)}
    </Box>
  );

  useEffect(() => {
    ComplianceService.getSourceOfTruth(transactionID, '').then(
      (res) => {
        setItems(res?.data?.data);
        setIsApproved(res?.data?.approved);
        setLoading(false);
      },
      (err) => {
        message.error(err.message);
        setItems({});
        setLoading(false);
      }
    );

    ComplianceService.getSourceOfTruth(
      transactionID,
      'TransactionTimeline'
    ).then(
      (res) => {
        setTransactionTimeline(res?.data?.data);
      },
      (err) => {
        message.error(err.message);
      }
    );

    ComplianceService.getTransactionByID(transactionID).then(
      (res) => {
        setTransaction(res.data);
      },
      (err) => {
        message.error(err.message);
      }
    );

    return () => {};
  }, []);

  const component = (
    <>
      {isModalOpen ? (
        <ResponsiveRenderer
          CompressedView={keyTransactionEventsMobile()}
          FullView={<></>}
        />
      ) : null}
      {!isModalOpen ? (
        <Box className="key-transactions">
          <Box className="main-title-container">
            <Breadcrumb style={{ margin: '1rem 0 1rem 0', display: 'flex' }}>
              <Breadcrumb.Item onClick={() => goToTransactions()}>
                <Typography fontSize="0.9rem" style={{ cursor: 'pointer' }}>
                  Transactions
                </Typography>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Typography fontSize="0.9rem">{transaction?.name}</Typography>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Typography fontSize="0.9rem">Facts</Typography>
              </Breadcrumb.Item>
            </Breadcrumb>

            <Button
              type="primary"
              style={{
                margin: '1rem 0 1rem 0',
              }}
              onClick={handleApprove}
              disabled={isApproved}
              loading={buttonLoading}
            >
              {buttonLoading
                ? 'Approving...'
                : isApproved
                  ? 'Details Approved'
                  : 'Approve Details'}
            </Button>
          </Box>

          <ResponsiveRenderer
            CompressedView={
              <Box onClick={handleOpenModal} className="key-title">
                See Key Transaction Events
              </Box>
            }
            FullView={<></>}
          />

          <Box className="content-wrapper">
            <Box className="left-column">
              {sectionList.length ? (
                sectionList.map((section, index) => (
                  <Paper key={index} className="paper-section">
                    <Box
                      className="header"
                      onClick={() => toggleSection(section.label)}
                    >
                      <Typography className="header-title">
                        <FolderCopyOutlined fontSize="small" /> {section.label}
                      </Typography>
                      <IconButton size="small">
                        {expandedSections[section.label] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                    </Box>
                    <Collapse in={expandedSections[section.label]}>
                      <Box className="section-content">
                        {'content' in section
                          ? section.content
                          : 'Section content goes here'}
                      </Box>
                    </Collapse>
                  </Paper>
                ))
              ) : (
                <Empty description="No Facts found" />
              )}
            </Box>
            <ResponsiveRenderer
              CompressedView={<></>}
              FullView={keyTransactionEvents(false)}
            />
          </Box>
        </Box>
      ) : null}
    </>
  );

  return <WaitAndLoadingWithSpinner loading={loading} component={component} />;
};

export default TransactionKeyFacts;
