import './UploadDocumentComponent.scss';

import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material';
import { Button, Modal, Upload, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import AddressForm from '../AddressForm/AddressForm';
import { AddressService } from '../AddressForm/AddressService/AddressService';
import { ComplianceService } from 'app/services/compliance';
import { InboxOutlined } from '@ant-design/icons';
import { OfficeService } from 'app/services/office';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import cross from 'assets/icons/highlight_off.svg';
import tick from 'assets/icons/tick.svg';
import { toast } from 'react-toastify';
import useDebounce from 'app/hooks/UseDebounce';
import { documentNameMap } from 'app/configs/documentNameMap';
const { Dragger } = Upload;

interface Address {
  unitNumber: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  placeId: string;
}

interface UploadDocumentComponentProps {
  onSuccess: () => void;
  initialDocument?: any;
}

const UploadDocumentComponent: React.FC<UploadDocumentComponentProps> = ({
  onSuccess,
  initialDocument = null,
}) => {
  const [modalState, setModalState] = useState({
    isUploadModalVisible: false,
    isCheckModalVisible: false,
  });

  const [fileData, setFileData] = useState({
    fileToUpload: null as any,
    previewUrl: null as string | null,
  });
  const [isNewTransaction, setIsNewTransaction] = useState(false);
  const [showAgentsList, setShowAgentsList] = useState(true);
  const [checkAlreadyExists, setCheckAlreadyExists] = useState(false);
  const [resetKey, setResetKey] = useState(0);

  const defaultAddress: Address = {
    unitNumber: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    placeId: '',
  };

  const [formData, setFormData] = useState({
    propertyAddress: { value: '', label: '' },
    selectedAgentName: { value: '', label: '' },
    selectedDocumentType: { value: '', label: '' },
    selectedOffice: { value: '', label: '' },
    address: defaultAddress,
  });

  const [loadingProperties, setLoadingProperties] = useState(false);
  const [propertyOptions, setPropertyOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [loadingAgents, setLoadingAgents] = useState(false);
  const [loadingOffices, setLoadingOffices] = useState(false);
  const [agentOptions, setAgentOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [officeOptions, setOfficeOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const documentTypeOptions = isNewTransaction
    ? [
        { value: 'IL_BW_EMR', label: 'Earnest Money Check' },
        { value: 'IL_BW_CC', label: 'Commission Check' },
        // { value: 'IL_BW_UC', label: 'Other' },
      ]
    : [
        { value: 'IL_BW_EMR', label: 'Earnest Money Check' },
        { value: 'IL_BW_CC', label: 'Commission Check' },
        // { value: 'IL_BW_UC', label: 'Other Check' },
        { value: 'UNKNOWN_DOC', label: 'Other Document' },
      ];

  const debouncedSearchTerm = useDebounce(
    formData?.selectedAgentName?.label,
    500
  );

  const debouncedPropertySearchTerm =
    useDebounce(formData?.propertyAddress?.label, 500) || '';

  const debouncedOfficeSearchTerm =
    useDebounce(formData?.selectedOffice?.label, 500) || '';

  const fetchAgents = useCallback(async () => {
    // if (!debouncedSearchTerm) return;

    setLoadingAgents(true);
    try {
      const response = await ComplianceService.getAgent(debouncedSearchTerm);
      const agents = response.data.data.map((agent: any) => ({
        label: agent.name,
        value: agent._id,
      }));
      setAgentOptions(agents);
    } catch (error) {
      message.error('Failed to load agents');
    } finally {
      setLoadingAgents(false);
    }
  }, [debouncedSearchTerm]);

  const fetchProperties = useCallback(async () => {
    if (!debouncedPropertySearchTerm) return;

    setLoadingProperties(true);
    try {
      const response = await ComplianceService.getPropertyByAddress(
        debouncedPropertySearchTerm
      );
      const properties = response.data.data.map((property: any) => ({
        label: property.name,
        value: property._id,
      }));
      setPropertyOptions(properties);
    } catch (error) {
      message.error('Failed to load properties');
    } finally {
      setLoadingProperties(false);
    }
  }, [debouncedPropertySearchTerm]);

  const fetchOffices = () => {
    // if (!debouncedOfficeSearchTerm) return;
    setLoadingOffices(true);
    try {
      OfficeService.getOffices('OfficeAdmin', debouncedOfficeSearchTerm).then(
        (response: any) => {
          const offices = response.data.data.map((office: any) => ({
            label: office.name,
            value: office._id,
          }));
          setOfficeOptions(offices);
        }
      );
    } catch (error) {
      return message.error('Failed to load offices');
    } finally {
      setLoadingOffices(false);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, [debouncedPropertySearchTerm]);

  useEffect(() => {
    fetchAgents();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchOffices();
  }, [debouncedOfficeSearchTerm]);

  const showModal = () => {
    setModalState({ ...modalState, isUploadModalVisible: true });
  };

  const handleOk = () => {
    if (fileData.fileToUpload) {
      uploadFile();
      setModalState({
        ...modalState,
        isUploadModalVisible: false,
        isCheckModalVisible: true,
      });
    } else {
      message.error('Please select a file to upload.');
    }
  };

  const handleCancel = () => {
    setModalState({ ...modalState, isUploadModalVisible: false });
    setFileData({ fileToUpload: null, previewUrl: null });
    setCheckAlreadyExists(false);
  };

  const uploadFile = () => {
    if (fileData.fileToUpload) {
      const objectUrl = URL.createObjectURL(
        fileData.fileToUpload as unknown as Blob
      );
      setFileData({ ...fileData, previewUrl: objectUrl });
    } else {
      message.error('Failed to load the file for preview.');
    }
  };

  useEffect(() => {
    return () => {
      if (fileData.previewUrl) {
        URL.revokeObjectURL(fileData.previewUrl);
      }
    };
  }, [fileData.previewUrl]);

  useEffect(() => {
    if (!modalState.isCheckModalVisible && !modalState.isUploadModalVisible) {
      setFormData({
        propertyAddress: { value: '', label: '' },
        selectedAgentName: { value: '', label: '' },
        selectedDocumentType: { value: '', label: '' },
        selectedOffice: { value: '', label: '' },
        address: defaultAddress,
      });
      setIsNewTransaction(false);
      setResetKey((prevKey) => prevKey + 1);
    }
  }, [modalState.isCheckModalVisible, modalState.isUploadModalVisible]);

  const handleCheckSubmit = async () => {
    if (
      (formData.propertyAddress || Object.keys(formData.address).length) &&
      formData.selectedDocumentType.value &&
      (formData.selectedOffice.value || initialDocument !== null) &&
      (fileData.fileToUpload || initialDocument !== null)
    ) {
      if (!formData.selectedOffice.value && initialDocument === null) {
        message.error('Please select a receiving office');
        return;
      }
      if (isNewTransaction) {
        if (!formData.selectedAgentName.value) {
          message.error('Please select an agent');
          return;
        }
        if (!formData.address.placeId) {
          message.error('Please select an address from dropdown');
          return;
        }
      }
      setIsButtonLoading(true);

      const {
        propertyAddress,
        selectedAgentName,
        selectedDocumentType,
        address,
      } = formData;
      const file = fileData.fileToUpload;

      if (isNewTransaction) {
        try {
          const unitNumber = address?.unitNumber;
          const placeId = address?.placeId;
          const response: any = await AddressService.validateAddress(
            placeId,
            unitNumber
          );
          if (response.status === 200) {
            setCheckAlreadyExists(true);
            setIsButtonLoading(false);
            setIsNewTransaction(false);
            setShowAgentsList(false);
            setFormData({
              ...formData,
              selectedAgentName: { value: '', label: '' },
              address: defaultAddress,
              propertyAddress: {
                label: response.data.name,
                value: response.data._id,
              },
            });
            return;
          }
        } catch (error: any) {}
      }

      try {
        if (isNewTransaction) {
          await ComplianceService.postCheck(
            selectedAgentName.value,
            selectedDocumentType.value,
            file,
            isNewTransaction,
            formData.selectedOffice.value,
            propertyAddress.value,
            '',
            address ? address : {}
          );
        } else {
          if (initialDocument !== null) {
            await ComplianceService.patchCheckFields(
              initialDocument?._id,
              initialDocument?.readyToDeposit,
              initialDocument?.notes,
              initialDocument?.processingStatus?.status,
              formData.selectedOffice.value !== ''
                ? formData.selectedOffice.value
                : initialDocument?.receivingOffice?._id,
              formData.selectedAgentName.value
            );
          } else {
            await ComplianceService.postCheck(
              '',
              selectedDocumentType.value,
              file,
              isNewTransaction,
              formData.selectedOffice.value,
              '',
              propertyAddress.value,
              {}
            );
          }
        }

        message.success('Check details saved successfully!');
        onSuccess();
        setModalState({ ...modalState, isCheckModalVisible: false });
        setFileData({ fileToUpload: null, previewUrl: null });
        setFormData({
          propertyAddress: { value: '', label: '' },
          selectedAgentName: { value: '', label: '' },
          selectedDocumentType: { value: '', label: '' },
          address: defaultAddress,
          selectedOffice: { value: '', label: '' },
        });
        setIsNewTransaction(false);
        setCheckAlreadyExists(false);
        setResetKey((prevKey) => prevKey + 1);
        setShowAgentsList(true);
      } catch (error: any) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message, {
            autoClose: false,
          });
        } else {
          toast.error('Failed to save check details', {
            autoClose: false,
          });
        }
      } finally {
        setIsButtonLoading(false);
      }
    } else {
      message.error('Please fill all the fields and upload a file.');
    }
  };

  const handleSelect = (field: keyof typeof formData, option: any) => {
    setFormData({ ...formData, [field]: option });
  };

  const handleCreateNewTransaction = () => {
    setIsNewTransaction(true);
    handleSelect('propertyAddress', {
      value: debouncedPropertySearchTerm,
      label: debouncedPropertySearchTerm,
    });
  };

  useEffect(() => {
    if (initialDocument !== null) {
      setModalState({ ...modalState, isCheckModalVisible: true });
      setFormData({
        propertyAddress: {
          value: initialDocument.transactionId,
          label: initialDocument.transactionName,
        },
        selectedAgentName: {
          value: initialDocument.agentId,
          label: initialDocument.agentName,
        },
        selectedDocumentType: {
          value: documentNameMap[initialDocument.type],
          label:
            documentTypeOptions.find(
              (option) => option.value === initialDocument.type
            )?.label || '',
        },
        selectedOffice: {
          value: initialDocument.receivingOffice?._id,
          label: initialDocument.receivingOffice?.name,
        },
        address: initialDocument.address,
      });
      setFileData({
        fileToUpload: null,
        previewUrl: null,
      });
    }
  }, [initialDocument]);

  return (
    <>
      {initialDocument === null && (
        <>
          <Button
            style={{ height: '40px', borderRadius: '12px' }}
            type="primary"
            onClick={showModal}
          >
            Upload Check
          </Button>
          <Modal
            title="Upload Check"
            open={modalState.isUploadModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Upload"
          >
            <Dragger
              style={{
                width: '100%',
                marginBottom: '1rem',
              }}
              accept=".pdf"
              beforeUpload={(file) => {
                setFileData({ ...fileData, fileToUpload: file });
                return false;
              }}
              onRemove={() => {
                setFileData({ fileToUpload: null, previewUrl: null });
              }}
              fileList={fileData.fileToUpload ? [fileData.fileToUpload] : []}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Drag and drop your file here or click to upload
              </p>
              <p className="ant-upload-hint">Only supports .pdf files</p>
            </Dragger>
          </Modal>
        </>
      )}
      <Modal
        title={
          initialDocument !== null
            ? 'Edit Check Details'
            : 'Upload Check Details'
        }
        open={modalState.isCheckModalVisible}
        onOk={handleCheckSubmit}
        onCancel={() => {
          setFileData({ fileToUpload: null, previewUrl: null });
          setFormData({
            propertyAddress: { value: '', label: '' },
            selectedAgentName: { value: '', label: '' },
            selectedDocumentType: { value: '', label: '' },
            address: defaultAddress,
            selectedOffice: { value: '', label: '' },
          });
          setResetKey((prev) => prev + 1);
          setCheckAlreadyExists(false);
          setShowAgentsList(true);
          setModalState({ ...modalState, isCheckModalVisible: false });
        }}
        okText={isButtonLoading ? 'Submitting...' : 'Submit'}
        cancelText="Cancel"
        okButtonProps={{ loading: isButtonLoading }}
        width={600}
        centered
      >
        {initialDocument === null && (
          <div className="file-preview">
            <img src={tick} alt="Tick" className="icon" />
            {fileData.previewUrl && <PictureAsPdfIcon />}
            <div className="file-info">
              <div className="file-name truncate">
                {fileData.fileToUpload?.name}
              </div>
              <div className="file-size">
                {(fileData.fileToUpload?.size / 1024).toFixed(0)} KB
              </div>
            </div>
            <img
              src={cross}
              alt="Close"
              className="icon cross-icon"
              onClick={() => {
                setFileData({ fileToUpload: null, previewUrl: null });
                setModalState({
                  isUploadModalVisible: true,
                  isCheckModalVisible: false,
                });
              }}
            />
          </div>
        )}

        {checkAlreadyExists ? (
          <div className="already-existing">
            We found a transaction with the given address. Proceed to upload the
            document in this transaction.
          </div>
        ) : null}
        <div className="dropdown-group">
          {!isNewTransaction ? (
            formData.propertyAddress?.value ? (
              <Chip
                label={formData.propertyAddress.label}
                onDelete={() => {
                  handleSelect('propertyAddress', null);
                  setPropertyOptions([]);
                  setIsNewTransaction(false);
                  setShowAgentsList(true);
                  setCheckAlreadyExists(false);
                }}
                disabled={initialDocument !== null}
                style={{
                  height: '3.5rem',
                  width: !showAgentsList ? '50%' : '',
                }}
              />
            ) : (
              <Autocomplete
                options={[
                  ...propertyOptions,
                  ...(debouncedPropertySearchTerm
                    ? [
                        {
                          label: `Create new transaction : "${debouncedPropertySearchTerm}"`,
                          value: debouncedPropertySearchTerm,
                        },
                      ]
                    : []),
                ]}
                getOptionLabel={(option) => option.label}
                loading={loadingProperties}
                onInputChange={(event, value) =>
                  handleSelect('propertyAddress', { label: value, value: '' })
                }
                onChange={(event, value) => {
                  if (value?.label?.startsWith('Create new transaction')) {
                    handleCreateNewTransaction();
                  } else {
                    handleSelect('propertyAddress', value);
                    setShowAgentsList(false);
                  }
                }}
                value={formData.propertyAddress}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Property Address"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingProperties ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )
          ) : (
            <AddressForm
              query={debouncedPropertySearchTerm}
              setFormData={setFormData}
            />
          )}

          <div className="dropdown-row">
            {showAgentsList ? (
              <Autocomplete
                key={`agent-${resetKey}`}
                options={agentOptions}
                getOptionLabel={(option) => option.label}
                loading={loadingAgents}
                onInputChange={(event, value) =>
                  handleSelect('selectedAgentName', { label: value, value: '' })
                }
                onChange={(event, value) =>
                  handleSelect('selectedAgentName', value)
                }
                value={formData.selectedAgentName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={
                      initialDocument !== null
                        ? "Enter Agent's Name"
                        : "Select Agent's Name"
                    }
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingAgents ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            ) : null}
            <Autocomplete
              key={`document-type-${resetKey}`}
              options={documentTypeOptions}
              getOptionLabel={(option) => option.label}
              onChange={(event, value) =>
                handleSelect('selectedDocumentType', value)
              }
              value={formData.selectedDocumentType}
              disabled={initialDocument !== null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    initialDocument !== null
                      ? 'Document Type'
                      : 'Select Document Type'
                  }
                  variant="outlined"
                  required
                  disabled={initialDocument !== null}
                />
              )}
            />
          </div>
          <div className="dropdown-row">
            <Autocomplete
              key={`office-${resetKey}`}
              options={officeOptions}
              getOptionLabel={(option) => option.label}
              loading={loadingOffices}
              onInputChange={(event, value) =>
                handleSelect('selectedOffice', { label: value, value: '' })
              }
              onChange={(event, value) => handleSelect('selectedOffice', value)}
              value={formData.selectedOffice}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={
                    initialDocument !== null
                      ? 'Enter Receiving Office'
                      : 'Select Receiving Office'
                  }
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingOffices ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadDocumentComponent;
