import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Link } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './ContactsList.scss';
import { ComplianceService } from 'app/services/compliance';
import { getCustomIdFromUrl } from 'app/utils/url';
import { Breadcrumb, message } from 'antd';
import { AppURLs } from 'app/utils/appURLs';
import { useNavigate } from 'react-router-dom';
import WaitAndLoadingWithSpinner from 'app/utils/super-components/waitAndLoadingWithSpinner';
import { useRecoilValue } from 'recoil';
import { transactionSourceAtom } from 'app/atomManagers/transactionSourceAtom';

const TransactionContactsList = () => {
  const [contacts, setContacts] = useState([]);
  const [transaction, setTransaction] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const transactionSource = useRecoilValue(transactionSourceAtom);

  const getContacts = async () => {
    setLoading(true);
    try {
      const response = await ComplianceService.getSourceOfTruth(
        getCustomIdFromUrl('transactionID'),
        'Contacts'
      );
      if (response?.data) {
        setContacts(response?.data?.data);
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getContacts();
    ComplianceService.getTransactionByID(
      getCustomIdFromUrl('transactionID')
    ).then(
      (res) => {
        setTransaction(res?.data);
      },
      (err) => {
        message.error(err.message);
      }
    );
  }, []);

  const goToTransactions = () => {
    if (transactionSource === 'compliance-transactions') {
      navigate(AppURLs.complianceDashboardComplianceTransactions());
    } else {
      navigate(AppURLs.complianceDashboardTransactions());
    }
  };

  const component = (
    <div className="contacts-list">
      <Breadcrumb style={{ margin: '1rem 0 1rem 0', display: 'flex' }}>
        <Breadcrumb.Item onClick={() => goToTransactions()}>
          <Typography fontSize="0.9rem" style={{ cursor: 'pointer' }}>
            {' '}
            Transactions{' '}
          </Typography>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Typography fontSize="0.9rem">{transaction?.name || '-'}</Typography>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Typography fontSize="0.9rem">Contacts</Typography>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="contact-cards">
        {Object.values(contacts).length > 0 ? (
          Object.values(contacts).map((contactCategory: any, index: number) => (
            <Card className="contact-card" key={index}>
              <CardContent>
                <Typography variant="h6" component="h3" gutterBottom>
                  {contactCategory?.title}
                </Typography>
                {contactCategory?.values?.length > 0 ? (
                  contactCategory.values.map((contact: any, idx: number) => (
                    <div key={idx}>
                      <Typography variant="body1" gutterBottom>
                        {contactCategory?.type === 'list'
                          ? (contact?.value?.filter(Boolean) || []).join(', ')
                          : contact?.value}
                      </Typography>
                      {contact?.phone && (
                        <Typography variant="body2" className="contact-info">
                          <PhoneIcon fontSize="small" />
                          <Link href={`tel:${contact?.phone}`}>
                            {contact?.phone}
                          </Link>
                        </Typography>
                      )}
                      {contact?.email && (
                        <Typography variant="body2" className="contact-info">
                          <EmailIcon fontSize="small" />
                          <Link href={`mailto:${contact?.email}`}>
                            {contact?.email}
                          </Link>
                        </Typography>
                      )}
                    </div>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No contacts available
                  </Typography>
                )}
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No contact data found.
          </Typography>
        )}
      </div>
    </div>
  );

  return <WaitAndLoadingWithSpinner loading={loading} component={component} />;
};

export default TransactionContactsList;
